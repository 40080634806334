import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

const BlogItems = ({blogHolderID}) => {
    const [tiles, setTiles] = useState([])
    const [pageNum, setPageNum] = useState(0)
    const [blogHolderLink, setBlogHolderLink] = useState()
    const allItemsRaw = useStaticQuery(graphql`
    {
        allSilverStripeDataObject(filter: {className: {eq: "BlogPost"}}, sort: {fields: BlogPost___date, order: DESC}) {
            nodes {
                BlogPost {
                    customTitle
                    summary
                    date(formatString: "Do MMMM YYYY")
                    elementalAreaID
                    categoryID
                    tileBlackTitle
                    imagePath
                    Category {  
                        UndigitalBlogCategory {
                          name
                        }
                    }
                    Parent {
                        link
                        silverstripe_id
                    }
                }
                SilverStripeSiteTree {
                    title
                }
                link
            }
        }
    }
  `)
    const allItemsNodes = allItemsRaw.allSilverStripeDataObject.nodes
    const [pageLimit, setPageLimit] = useState(8)
    
    useEffect(() => {
        var _allData = []
        const search = typeof window !== "undefined" ? window.location.search : "/"
        const params = new URLSearchParams(search)
        var keyword = null;
        if(params.get("page")){
            setPageNum(parseInt(params.get("page")))
        }
        if(params.get("keyword")){
            keyword = params.get("keyword").toLowerCase()
        }
        const nl2br = text =>
            text
        .split(/(?:\r\n|\r|\n)/)
        .reduce(
          (res, frag, i, arr) => [
            ...res,
            frag,
            ...(i < arr.length - 1 && [React.createElement("br")])
          ],
          []
        )
        var i = 0
        for (i=0; i<allItemsNodes.length;i++ ) {
            var node = allItemsNodes[i]
            let _post = node.BlogPost
            let _blogHolderID = _post.Parent.silverstripe_id
            if(_blogHolderID !== blogHolderID) continue
            if(blogHolderLink === null){
                setBlogHolderLink(_post.Parent.link)
            }
            _post.title = node.SilverStripeSiteTree.title
            if(_post.imagePath){
                _post.image = _post.imagePath
            }
            _post.link = node.link
            _post._title = _post.title
            if(_post.Category){
                _post.category = _post.Category.UndigitalBlogCategory.name
            }
            if(_post.customTitle !== ''){
                _post._title = _post.customTitle.replace(/(?:\r\n|\r|\n)/g, '<br />')
            }
            let match = true
            if (keyword) {
                let postTitle = _post.title.toLowerCase()            
                let matched_title = postTitle.search(keyword)
                let postCategory = _post.category.toLowerCase()            
                let matched_category = postCategory.search(keyword)    
                if (matched_title === -1 && matched_category === -1) {
                    match = false
                }
            }
            if (match) {
                _allData.push(_post)
            }
        }
        setTiles([..._allData])
    }, [allItemsNodes, blogHolderLink])

    const _total_items = tiles.length
    const _total_pages = Math.ceil(_total_items / pageLimit)
    var prevLink, nextLink = null
    
    if (pageNum > _total_pages) {
        pageNum = _total_pages
    }    
    var offset = 0
    var endOffset = pageLimit
    if(pageNum >= 1){
        offset = (pageNum - 1) * pageLimit
        endOffset = pageNum * pageLimit
    }
    var currentData = tiles.slice(offset, endOffset)
    var pages = []
    
    if(_total_pages > 1){
        for (var j=1; j<=_total_pages; j++) {
            let page = {}
            page.pageNumber = j
            page.link = blogHolderLink + '?page=' + j
            pages.push(page)            
        }        
        if(pageNum > 1){
            prevLink = blogHolderLink + '?page=' + (pageNum - 1)
        }
        if(pageNum < _total_pages){
            nextLink = blogHolderLink + '?page=' + (pageNum + 1)
        }
    }    
  return (
        <div className="container my-4">
            <div className="row gutters-10">
                {currentData.map((post, idx) => (
                    <div key={`post-${idx}`} className="col-sm-6 col-lg-3">
                      <div className="grid-item">
                        <div className="grid-item-inner">
                          <img src={post.image} alt={post.title} />
                          <h4 className={`grid-title${post.tileBlackTitle == 1 ? ' text-black' : ''}`} dangerouslySetInnerHTML={{ __html: post._title }}/>
                          <a href={post.link} className="grid-caption related-articles-caption">
                            <div className="inner">
                              <div className="related-articles-category">{post.category}</div>
                              <div className="related-articles-date">
                                {post.date}
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                ))}
            </div>
            {pages.length > 1 && (
            <ul className="pagination custom-pagination justify-content-center mt-4">
                {prevLink && (
                    <li className="page-item">
                        <a className="page-link" href={prevLink}>Prev</a>
                    </li>
                )}
                {pages.map((page, idx) => (
                <li key={`paging-${idx}`} className="page-item active"><a className="page-link" href={page.link}>{page.pageNumber}</a></li>
                ))}
                {nextLink && (
                    <li className="page-item">
                        <a className="page-link" href={nextLink}>Next</a>
                    </li>
                )}
            </ul>
            )}
      </div>
    )
}

export default BlogItems
    